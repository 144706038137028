import React from 'react';
import Home from '../containers/Home';
import BookATable from '../containers/BookATable';
import ContactUs from '../containers/ContactUs';
import { Helmet } from 'react-helmet';

const slides = [
  {
    title: '- Book a table -',
    showButton: false,
  },
];

const BookATablePage = () => (
  <>
    <Helmet>
        <title>Book a Table | Botafogo Brazilian Grill Huddersfield</title>
        <meta name="description" content="Book here! Come visit our Traditional Brazilian Rodizio in Huddersfield, UK" />
    </Helmet>
    <Home classNames="is-restaurant-menu is-booking" menu slides={slides} />
    <BookATable />
    <ContactUs />
  </>
)

export default BookATablePage;
